import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "./src/styles/_global.css"

import React from "react"

import { RecombeeContextProvider } from "./src/contexts/RecombeeContext"

export const wrapRootElement = ({ element }) => (
  <RecombeeContextProvider>{element}</RecombeeContextProvider>
)

/* exports.onRouteUpdate = ({ location }, options) => {
  if (typeof Analytics !== "undefined") {
    if (options && options.debug) {
      console.log("analytics.page() called")
    }
    // On every route change fire this code!!
    Analytics.page()
  }
} */
