import React from "react"

import { v4 as uuidv4 } from "uuid"

const RecombeeContext = React.createContext()

const init = () => {
  return (
    typeof window !== "undefined" &&
    window.recombee &&
    new window.recombee.ApiClient(
      "cubewise-dev",
      "lD655OeqUD1Flqci69wLXkpiU5fdrMC5Fdriutx8jfQ7rxaoYqhAtiGRP6nUL8Ky",
      {
        region: "eu-west",
      }
    )
  )
}

const userId = () => {
  const cookieName = "cubewiseAnonymousId"

  let cubewiseAnonymousId =
    typeof window !== "undefined" && window.localStorage.getItem(cookieName)
  if (cubewiseAnonymousId) return cubewiseAnonymousId

  cubewiseAnonymousId = uuidv4()
  typeof window !== "undefined" &&
    window.localStorage.setItem(cookieName, cubewiseAnonymousId)

  return cubewiseAnonymousId
}

const initialState = {
  recombee: {
    client: null,
    userId: userId(),
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return {
        ...state,
        client: action.payload.client,
      }
    default:
      return state
  }
}

const RecombeeContextProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState.recombee)
  return (
    <RecombeeContext.Provider
      value={{
        ...state,
        Init: () => {
          if (state.client) return
          dispatch({
            type: "init",
            payload: {
              client: init(),
            },
          })
        },
        AddDetailView: (itemId, options = {}) => {
          if (!state.client) return Promise.reject()
          return state.client
            .send(
              new window.recombee.AddDetailView(state.userId, itemId, options)
            )
            .then(res => {
              return Promise.resolve(res)
            })
            .catch(err => console.log(err))
        },
        RecommendItemsToUser: (count, minRelevance = "low") => {
          if (!state.client) return Promise.reject()
          return state.client
            .send(
              new window.recombee.RecommendItemsToUser(state.userId, count, {
                minRelevance: minRelevance,
                returnProperties: true,
                includedProperties: [
                  "sourceId",
                  "excerpt",
                  "title",
                  "categories",
                  "uri",
                ],
              })
            )
            .then(res => {
              return Promise.resolve(res)
            })
            .catch(err => console.log(err))
        },
        SearchContent: (token, count) => {
          if (!state.client) return Promise.reject()
          return state.client
            .send(
              new window.recombee.SearchItems(state.userId, token, count, {
                returnProperties: true,
                includedProperties: ["sourceId", "excerpt", "title", "categories", "uri"],
              })
            )
            .then(res => {
              return Promise.resolve(res)
            })
            .catch(err => console.log(err))
        },
      }}
    >
      {props.children}
    </RecombeeContext.Provider>
  )
}

const RecombeeContextConsumer = RecombeeContext.Consumer

export { RecombeeContextProvider, RecombeeContextConsumer, RecombeeContext }
